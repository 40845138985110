<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-center justify-center fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title :title="id === 'nuevo' ? 'Nuevo color de rosas' : name"></app-title>
        </v-flex>
        <v-flex xs12 lg12 xl8>
          <v-card>
            <app-title-card
              :title="'Rellene el formulario'"
              :subtitle="'Todos los campos son obligatorios'"
            ></app-title-card>
            <v-divider></v-divider>
            <v-card-text>
              <v-layout wrap align-center justify-center fill-height>
                <v-flex xs12 sm3>
                  <v-text-field v-model="name" label="Nombre" placeholder="Rosado"></v-text-field>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field v-model="price" label="Precio" placeholder="10000" type="number"></v-text-field>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    v-model="price_high"
                    label="Precio Alta demanda"
                    placeholder="10000"
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-switch v-model="status" color="secondary" label="Activo" hide-details></v-switch>
                </v-flex>
                <v-flex xs12>
                  <QuillEditorFormat
                    v-model="description"
                    :reference="'descriptionOrder'"
                    placeholder="Descripcion (no obligatorio)"
                  >
                  </QuillEditorFormat>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent" flat @click="save"> Listo </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex v-if="id !== 'nuevo'" xs12 lg12 xl8>
          <v-card>
            <app-title-card :title="'Fotos'"></app-title-card>
            <v-divider></v-divider>
            <v-card-text>
              <FilePond
                ref="pond"
                class="file-pond"
                name="image"
                label-idle='Arrastra y suelta tus archivos o <span class="filepond--label-action"> Buscalos </span>'
                allow-multiple="true"
                accepted-file-types="image/jpg, image/jpeg, image/png"
                :server="{ process }"
                data-max-file-size="2MB"
                data-max-files="1"
                :files="myFiles"
                @error="onError"
              />

              <v-data-table :headers="headers" :items="images" class="elevation-0 ma-0" hide-actions>
                <template v-slot:items="props">
                  <td style="width: 80px;" class="text-xs-center">
                    {{ props.item.orden }}
                  </td>
                  <td>
                    <a :href="props.item.url" target="_blank" download>
                      <img :src="props.item.url" style="max-width:70px;" />
                    </a>
                  </td>
                  <td width="200" class="text-xs-center">
                    <v-btn
                      :disabled="props.index === 0"
                      class="ma-0"
                      flat
                      icon
                      @click.stop="changePosition('up', props.item.id)"
                    >
                      <v-icon>keyboard_arrow_up</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="props.index + 1 === images.length"
                      class="ma-0"
                      flat
                      icon
                      @click.stop="changePosition('down', props.item.id)"
                    >
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-btn>
                    <v-btn color="back" class="ma-0" flat icon @click="deleteImage(props.item)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import { GET_COLORS_CAJA } from '../../../config'
import appTitle from '../../useful/title.vue'
import appTitleCard from '../../useful/titleCard.vue'
import QuillEditorFormat from '../../useful/quillEditorFormat.vue'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
  name: 'ProductAdd',
  components: { appTitle, appTitleCard, QuillEditorFormat, FilePond },
  props: ['id'],
  data: () => ({
    myFiles: [],
    images: [],
    name: null,
    status: true,
    price: null,
    price_high: null,
    description: null,
    headers: [
      { text: 'Orden', align: 'right', sortable: false },
      { text: 'Imagen', align: 'left', sortable: false },
      { text: '', align: 'center', sortable: false }
    ]
  }),
  mounted() {
    const { id } = this
    if (id !== 'nuevo') {
      this.loading = true
      this.getInfo(id)
    }
  },
  methods: {
    async save() {
      try {
        let { id, name, status, price, price_high, description } = this
        if (name === '' || name === null) {
          return
        }
        if (parseInt(price, 10) <= 0 || price === null) {
          return
        }
        this.loading = true
        const params = {
          name,
          status,
          price,
          price_high,
          description
        }
        if (id !== 'nuevo') {
          await this.$http.put(`${GET_COLORS_CAJA}/${id}`, params)
        }
        if (id === 'nuevo') {
          const res = await this.$http.post(GET_COLORS_CAJA, params)
          id = res.data
        }
        this.getInfo(id)
        this.$router.replace({ name: 'productsCajaId', params: { id } })
      } catch (e) {
        console.log('ERROR=>', e)
      }
    },
    async getInfo(id) {
      const res = await this.$http.get(`${GET_COLORS_CAJA}/${id}`)
      this.name = res.data.name
      this.status = res.data.status
      this.price = res.data.price
      this.price_high = res.data.price_high
      this.description = res.data.description
      this.images = res.data.images
      this.loading = false
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      const { id } = this
      const formData = new FormData()
      formData.append(fieldName, file)
      if (file.size < 2000000) {
        try {
          await this.$http.post(`${GET_COLORS_CAJA}/${id}/img`, formData, {
            headers: { 'content-type': 'multipart/form-data' }
          })
          this.getInfo(id)
          load('success')
        } catch (e) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error al subir el archivo'
          })
          error()
        }
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'El tamaño maximo de archivo es de 1MB'
        })
        error()
      }
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        }
      }
    },
    onError(error, file) {
      console.log(error, file)
    }
  }
}
</script>
