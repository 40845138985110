<template>
  <div :class="reference">
    <quill-editor :ref="reference" :content="value" :options="editorOption" @change="onEditorChange($event)">
      <div id="toolbar" slot="toolbar">
        <!-- Add a bold button -->
        <button class="ql-bold">Bold</button>
        <button class="ql-italic">Italic</button>
        <!-- Add font size dropdown -->
        <select class="ql-size">
          <option value="small"></option>
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
        <select class="ql-font">
          <option value="raleway" selected>Raleway</option>
          <option value="shadows-into-light">Shadows Into Light</option>
          <option value="courier-new">Courier New</option>
          <option value="times-new-roman">Times New Roman</option>
          <option value="pacifico">Pacifico</option>
          <option value="patrick-hand">Patrick Hand</option>
          <option value="satisfy">Satisfy</option>
        </select>
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
        <button class="ql-link"></button>
        <button class="ql-image"></button>
        <button class="ql-video"></button>
        <button class="ql-blockquote"></button>
        <span class="ql-formats">
          <button class="ql-clean"></button>
        </span>
      </div>
    </quill-editor>
  </div>
</template>

<script>
import Quill from 'quill'

export default {
  props: ['value', 'reference', 'placeholder'],
  computed: {
    editor() {
      return this.$refs[this.reference].quill
    },
    editorOption() {
      return {
        placeholder: this.placeholder,
        modules: {
          toolbar: {
            container: `.${this.reference} #toolbar`,
            handlers: {
              image: () => {
                var range = this.editor.getSelection()
                var value = prompt('What is the image URL')
                if (value) {
                  this.editor.insertEmbed(range.index, 'image', value, Quill.sources.USER)
                }
              },
              video: () => {
                let url = prompt('Enter Video URL: ')
                url = this.getVideoUrl(url)
                let range = this.editor.getSelection()
                if (url != null) {
                  this.editor.insertEmbed(range.index, 'video', url)
                }
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    console.log('this is current quill instance object', this.editor)
  },
  methods: {
    onEditorChange(event) {
      this.$emit('input', event.html)
    },
    imageHandler() {},
    getVideoUrl(url) {
      let match =
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
        url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/)
      if (match && match[2].length === 11) {
        console.log(match[2])
        return 'https' + '://www.youtube.com/embed/' + match[2] + '?showinfo=0'
      }
      if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
        // eslint-disable-line no-cond-assign
        return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/'
      }
      return null
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Pacifico|Patrick+Hand|Raleway|Satisfy|Shadows+Into+Light&display=swap');

.v-menu__activator a {
  width: 100%;
}
#toolbar .ql-font span[data-label='Raleway']::before {
  font-family: 'Raleway';
}
#toolbar .ql-font span[data-label='Courier New']::before {
  font-family: 'Courier New';
}
#toolbar .ql-font span[data-label='Times New Roman']::before {
  font-family: 'Times New Roman';
}
#toolbar .ql-font span[data-label='Pacifico']::before {
  font-family: 'Pacifico';
}
#toolbar .ql-font span[data-label='Patrick Hand']::before {
  font-family: 'Patrick Hand';
}
#toolbar .ql-font span[data-label='Satisfy']::before {
  font-family: 'Satisfy';
}
#toolbar .ql-font span[data-label='Shadows Into Light']::before {
  font-family: 'Shadows Into Light';
}
.ql-font-raleway {
  font-family: 'Raleway';
}
.ql-font-shadows-into-light {
  font-family: 'Shadows Into Light';
}
.ql-font-courier-new {
  font-family: 'Courier New';
}
.ql-font-times-new-roman {
  font-family: 'Times New Roman';
}
.ql-font-pacifico {
  font-family: 'Pacifico';
}
.ql-font-patrick-hand {
  font-family: 'Patrick Hand';
}
.ql-font-satisfy {
  font-family: 'Satisfy';
}
.ql-container {
  font-size: 14px;
  font-family: 'Raleway';
  white-space: pre-line;
}
.ql-editor {
  font-size: 14px;
  font-family: 'Raleway';
  white-space: pre-line;
}
/* We do not set Sans Serif since it is the default font */
</style>
